@import '../partials';

.blockVideo {
  @include content-container;
  width: 100%;

  &__video {
    position: relative;
    padding-bottom: 56.25%; // 16:9 ratio
    padding-top: rem(30);
    height: 0;
    overflow: hidden;

    img {
      max-width: 100%;
    }
  }

  &__boundary {
    @include interior-content-container;
  }

  &__title {
    @include primary-body-header;
    text-transform: uppercase;
    padding-bottom: rem(16);
  }

  &__caption {
    //@include secondary-body-header;
    padding-top: rem(14);
  }
}

.blockVideo iframe,
.blockVideo object,
.blockVideo embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

html.single-exhibitions,
html.single-events {
  .blockVideo {

    &__boundary {
      width: auto;
      padding: 0;
    }
  }
}